import { useState, useEffect } from 'react';
import { LinkButton } from '../../components/linkButton';
import { useNavigate } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  addKamaitachiDeliveryState,
  addKamaitachiDeliveryFormState,
  addKamaitachiTotalState,
  addKamaitachiAddressState,
} from '../../store/atoms';
import { importAll } from '../../utils/image';
import { Wrapper } from './scss';
import { Calender } from '../../components/calender2';
import { PullDown } from '../../components/pullDown';
import dayjs from 'dayjs';
import { FormHeader } from '../../components/fiveStepBar';

export const KamaitachiDeliveryInfo = () => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const navigate = useNavigate();
  const total = useRecoilValue(addKamaitachiTotalState);
  const inputFormWidth = [340, 512, 720];
  const inputFormHalfWidth = [160, 246, 350];
  const inputFormHeight = [32, 38, 44];
  const linkButtonHeight = [48, 52, 60];
  const [delivery, setDelivery] = useRecoilState(addKamaitachiDeliveryState);
  const [recoilForm, setRecoilForm] = useRecoilState(addKamaitachiDeliveryFormState);
  const [address, setAddress] = useRecoilState(addKamaitachiAddressState);
  const [addressList, setAddressList] = useState([]);
  const [isValid, setIsValid] = useState(recoilForm.isValid);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(delivery?.date);
  const [deliveryTime, setDeliveryTime] = useState(delivery?.time);
  const [showCalender, setShowCalender] = useState(false);
  const [form, setForm] = useState({
    deliveryDate: recoilForm.content.deliveryDate,
    deliveryTime: recoilForm.content.deliveryTime,
  });
  const setFormByProp = (key, value) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const setDeliveryByProp = (key, value) => {
    setDelivery((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = value;
      return future;
    });
  };

  // MEMO : フォーム全体が有効かどうかを判定する
  useEffect(() => {
    if (isValid) return;
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    }
  }, [form]);

  useEffect(() => {
    if (!isValid) setButtonIsLoading(false);
  }, [isValid]);

  useEffect(() => {
    if (deliveryDate) {
      setDeliveryByProp('date', deliveryDate);
      setFormByProp('deliveryDate', 'valid');
    } else {
      setFormByProp('deliveryDate', 'not filled');
      setIsValid(false);
    }
  }, [deliveryDate]);

  useEffect(() => {
    if (deliveryTime === delivery?.time) return;
    if (deliveryTime) {
      setDeliveryByProp('time', deliveryTime);
      setFormByProp('deliveryTime', 'valid');
    }
  }, [deliveryTime]);

  useEffect(() => {
    console.log(address);
    const addressList = address.list.map((item, key) => {
      return (
        <div
          key={key}
          className="addressList"
          onClick={() => {
            setAddress({
              ...address,
              selected: key,
            });
          }}
        >
          <div>
            <div>
              {item.lastName} {item.firstName}
            </div>
            <div>〒{item.zipCode}</div>
            <div>
              {item.prefecture}
              {item.city}
              {item.suburb}
              {item.street}
            </div>
            <div>{item.building}</div>
            <div>{item.corporation}</div>
            <div>{item.phoneNum}</div>
          </div>
          <div className="rightWrap">
            {address.selected === key && key > 0 && (
              <div
                className="editButton"
                onClick={() => {
                  navigate(`/tachibana-ran-collabo/delivery/address-info?id=${key}`);
                }}
              >
                編集
              </div>
            )}
            <div className="checkBox">{address.selected === key ? '◉' : '○'}</div>
          </div>
        </div>
      );
    });
    setAddressList(addressList);
  }, [address]);

  useEffect(() => {
    // MEMO : 直接アクセスされた場合のリダイレクト
    if (total.totalCount === 0) {
      navigate('/tachibana-ran-collabo');
    }
  }, []);

  return (
    <Wrapper
      inputFormWidth={inputFormWidth}
      inputFormHalfWidth={inputFormHalfWidth}
      inputFormHeight={inputFormHeight}
      linkButtonHeight={linkButtonHeight}
    >
      <FormHeader step={2} title="配送" />
      <div className="addressTitle">配送先</div>
      {addressList}
      <div
        className="addAddressButtonWrap"
        onClick={() => {
          setRecoilForm({
            isValid: isValid,
            content: form,
          });
          navigate(`/tachibana-ran-collabo/delivery/address-info?id=${address.list.length}`);
        }}
      >
        <div className="addAddressButton">別の配送先を追加する</div>
        <img src={image['arrow_down.svg']} />
      </div>

      <PullDown
        className="marginBottom"
        title="配送希望日"
        description={deliveryDate && dayjs(deliveryDate).format('YYYY年MM月DD日')}
        setItem={(value) => {
          const transformList = {
            '2025年3月14日': '2025-03-14',
          };
          setDeliveryDate(transformList[value]);
        }}
      >
        <p>2025年3月14日</p>
      </PullDown>
      {/* {showCalender && (
        <Calender
          className="marginBottom"
          width={[320, 420, 480]}
          startDate={dayjs('2024-07-15')}
          endDate={dayjs('2024-08-13')}
          selectedDate={dayjs(deliveryDate)}
          setSelectedDate={(date) => {
            setDeliveryDate(date && date.format('YYYY-MM-DD'));
          }}
        />
      )} */}
      <PullDown className="" title="配送希望時間" description={deliveryTime} setItem={setDeliveryTime}>
        <p>8:00~12:00</p>
        <p>14:00~16:00</p>
        <p>16:00~18:00</p>
        <p>18:00~20:00</p>
        <p>19:00~21:00</p>
      </PullDown>
      <LinkButton
        className="linkButton"
        width={inputFormWidth}
        height={linkButtonHeight}
        text="決済情報の入力へ進む"
        color="black"
        active={isValid}
        loading={buttonIsLoading}
        onClick={() => {
          setButtonIsLoading(true);
          setRecoilForm({
            isValid: isValid,
            content: form,
          });
          navigate('/tachibana-ran-collabo/delivery/payment');
        }}
      />
      <LinkButton
        width={inputFormWidth}
        height={linkButtonHeight}
        text="お客様情報に戻る"
        color="white"
        arrow="left"
        active={true}
        onClick={() => {
          setButtonIsLoading(true);
          setRecoilForm({
            isValid: isValid,
            content: form,
          });
          navigate('/tachibana-ran-collabo/delivery/customer-info');
        }}
      />
    </Wrapper>
  );
};
